// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["HiKBF20XH"];

const variantClassNames = {HiKBF20XH: "framer-v-1k51zwk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, EVLJlvwtC: title ?? props.EVLJlvwtC ?? "Lorem Ipsum"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EVLJlvwtC, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "HiKBF20XH", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-zY2Yj", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1k51zwk", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"HiKBF20XH"} ref={ref} style={{backgroundColor: "rgb(26, 26, 26)", borderBottomLeftRadius: 80, borderBottomRightRadius: 80, borderTopLeftRadius: 80, borderTopRightRadius: 80, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QUJDIERpYXR5cGUtNTAw", "--framer-font-family": "\"ABC Diatype\", sans-serif", "--framer-font-weight": "500", "--framer-line-height": "150%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Lorem Ipsum</motion.p></React.Fragment>} className={"framer-28uond"} data-framer-name={"CTA"} fonts={["GF;ABC Diatype-500"]} layoutDependency={layoutDependency} layoutId={"I181:21678;1:100"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={EVLJlvwtC} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-zY2Yj [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zY2Yj .framer-858a7q { display: block; }", ".framer-zY2Yj .framer-1k51zwk { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 18px 50px 18px 50px; position: relative; width: min-content; }", ".framer-zY2Yj .framer-28uond { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zY2Yj .framer-1k51zwk { gap: 0px; } .framer-zY2Yj .framer-1k51zwk > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-zY2Yj .framer-1k51zwk > :first-child { margin-left: 0px; } .framer-zY2Yj .framer-1k51zwk > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 193
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"EVLJlvwtC":"title"}
 * @framerImmutableVariables false
 */
const FramerOM0H1VkGW: React.ComponentType<Props> = withCSS(Component, css, "framer-zY2Yj") as typeof Component;
export default FramerOM0H1VkGW;

FramerOM0H1VkGW.displayName = "Button/Hover";

FramerOM0H1VkGW.defaultProps = {height: 60, width: 193};

addPropertyControls(FramerOM0H1VkGW, {EVLJlvwtC: {defaultValue: "Lorem Ipsum", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerOM0H1VkGW, [])